import axios from 'axios';
const token = localStorage.getItem("token") || "";

const config = {
	headers: {
		'x-api-key': process.env.REACT_APP_TOKEN,
		'X-Requested-With': 'XMLHttpRequest',
		'Content-Type': 'application/json; charset=utf-8'
	}
};

const Axios = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	headers: {
	  Authorization: `Bearer ${token}`,
	  "X-Requested-With": "XMLHttpRequest",
	  "Content-Type": "application/json; charset=utf-8",
	},
  });

const AxiosForEonAccess = axios.create({
	baseURL: process.env.REACT_APP_TS_BASE_URL
});

const AxiosForEonMfg = axios.create({
	baseURL: process.env.REACT_APP_EON_MFG_TS_BASE_URL
});

const fetchTSUrls = (caseId, tsId, doctorId) => {
	return Axios.get(
	  `/doctors/${doctorId}/aligner_cases/${caseId}/treatment_setups/${tsId}`,
	  {
		headers: {
		  Authorization: `Bearer ${token}`,
		  "Content-Type": "application/json; charset=utf-8",
		},
	  }
	);
  };

const fetchTSUrlsForEonAcess = viewerId => {
	return AxiosForEonAccess.get(`/peep/treatmentSetupFiles/${viewerId}`);
};

const fetchTSUrlsForEonMfg = viewerId => {
	return AxiosForEonMfg.get(`/viewer/${viewerId}`, config);
};

export { fetchTSUrlsForEonAcess, fetchTSUrlsForEonMfg, fetchTSUrls };
