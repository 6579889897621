import { getParams } from "./getParams";

let params = getParams()

let configOptions = [
    'isZoom',
    'viewerId' ,
    'isDarkMode',
    'isAttachment',
    'isSuperImpose',
    'isM', 
    'isAccess', 
    'caseId', 
    'tsId', 
    'doctorId', 
    'showBiteRecord', 
    'position', 
    'files',
    'isAdmin',
    'themeColor', 
    'dental_notation',
    'isDoctor'
];

export const handlingconfigrasions = (setTsOptions, props) => {
    let result = {}
    return new Promise((resolve) => {
        configOptions.forEach((elem) => {
            const isElemBoolean = elem.slice(0, 2) === 'is'
            if(params[elem]) {
                result[elem] = isElemBoolean ? params[elem] == 'true' : params[elem] 
            } if(props[elem])  {
                result[elem] = props[elem]
            } else {
                return
            }
        });
        setTsOptions((prevOptions) => ({...prevOptions, ...result}))
        resolve(result)
        if (result.themeColor.includes('#')) {
            result.themeColor = result.themeColor;
        } if(!result.themeColor.includes('#')) {
            result.themeColor = '#' + result.themeColor;
        } 
    }) 
}