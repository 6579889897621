import { getParams } from "./getParams";
import { apiResolver } from "./apiResolver";
import { iprDomeData } from "../TS-VIEWER/iprdata";

export const resolvePromise = (viewerId, isAccess, caseId, tsId, doctorId, files, isM, isAdmin,setIPRDataValues) => {
  let params = getParams();
  let resolverPromise;
  if (params["path"])
    resolverPromise = new Promise(function (resolve, reject) {
      
      setIPRDataValues(iprDomeData)
      resolve(function (m) {
        return `${process.env.PUBLIC_URL }/` + params["path"] + "/" + m;
      });
    });
    else  resolverPromise = apiResolver(viewerId, isAccess, caseId, tsId, doctorId, isM, files, isAdmin, setIPRDataValues)

  return resolverPromise;
};
